var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var ssa=function(a,b){return new $CLJS.ie(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Hc(d);else break a}return d},null,null)},tsa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt(a,b),ssa(a,b)],null)},P4=function(a){var b=$CLJS.mk.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.I4);c=$CLJS.J.g(c,$CLJS.J4);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.D4.h;d=$CLJS.mh.h(d);var e=$CLJS.q($CLJS.A4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.D4,d);c=new $CLJS.h(null,2,[$CLJS.H4,c,$CLJS.K4,c],null)}else c=new $CLJS.h(null,1,[$CLJS.K4,2],null);b=b.call($CLJS.mk,$CLJS.H([c,a]));return $CLJS.n($CLJS.L4.h(a))?$CLJS.R.l($CLJS.Nj.g(b,$CLJS.L4),$CLJS.K4,$CLJS.L4.h(a),$CLJS.H([$CLJS.H4,$CLJS.L4.h(a)])):b},Q4=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=Q4[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Q4._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("NumberFormatter.format-number-basic",
a);}return a},usa=function(a,b){var c=$CLJS.Ly(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.gi,!0],null)]));a=$CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.Mi.h(d),"exponentMinusSign")},c))?c:function(){var d=tsa(function(f){return $CLJS.Tj.g($CLJS.Mi.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.bf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Mi,"exponentPlusSign",$CLJS.$i,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ne.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.Mi);e=$CLJS.J.g(e,$CLJS.$i);switch(d){case "exponentSeparator":return"e";default:return e}},a))},vsa=function(a,b){switch($CLJS.J4.h(b)){case "scientific":return function(c){return usa(a,c)};default:return function(c){return a.format(c)}}},R4=function(a,b,c,d,e){this.options=a;this.Bg=b;this.Eg=c;this.de=d;this.li=e;this.C=393216;this.I=0},wsa=function(a,b){return $CLJS.Ne.g($CLJS.p,function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.hd(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.Mi);A=$CLJS.J.g(A,$CLJS.$i);var C=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.Mi);t=$CLJS.J.g(t,$CLJS.$i);if($CLJS.n(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ae($CLJS.E.g(u,"integer")?b:t,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}($CLJS.Ly(a.Bg.formatToParts(1),$CLJS.H([$CLJS.gi,!0])))}())},S4=function(a){var b=$CLJS.E.g($CLJS.J4.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.ok($CLJS.Jz($CLJS.Ta,$CLJS.zg([$CLJS.psa,
$CLJS.hsa,$CLJS.ksa,$CLJS.msa,$CLJS.rsa,$CLJS.I4,$CLJS.lsa,$CLJS.nsa,$CLJS.isa,$CLJS.jsa],[$CLJS.K4.g(a,b),$CLJS.N4.h(a),$CLJS.M4.h(a),$CLJS.osa.h(a),$CLJS.E.g($CLJS.J4.h(a),"scientific")?null:$CLJS.J4.g(a,"decimal"),$CLJS.I4.h(a),!0,$CLJS.H4.g(a,b),$CLJS.E.g($CLJS.J4.h(a),"scientific")?"scientific":null,$CLJS.O4.h(a)]))));var c=$CLJS.N4.h(a);var d=$CLJS.I4.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=vsa(b,a);return new R4(a,b,c,d,$CLJS.N)},T4=function(a,b){return S4(P4(b)).Oe(null,a)},V4=
function(a,b){var c=$CLJS.Nj.l(b,$CLJS.AA,$CLJS.H([$CLJS.J4])),d=$CLJS.yz(a);if(0===a)return"0";if(1E3>d)return $CLJS.U4.g?$CLJS.U4.g(a,c):$CLJS.U4.call(null,a,c);b=$CLJS.z($CLJS.ff(function(f){return d>=$CLJS.z(f)},xsa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.mk.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.H4,1,$CLJS.K4,1],null)]));return $CLJS.U4.g?$CLJS.U4.g(f,k):$CLJS.U4.call(null,f,k)}()),$CLJS.p.h(b)].join("")},ysa=function(a,b){var c=P4(b);if($CLJS.n(W4.h(c)))b=
W4.h(c);else if($CLJS.Sa($CLJS.L4.h(c))&&$CLJS.Sa($CLJS.H4.h(c))&&$CLJS.Tj.g($CLJS.J4.h(c),"currency")&&$CLJS.yz(a)<($CLJS.E.g($CLJS.J4.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Nj.g(c,$CLJS.K4);c=$CLJS.O4.g(c,0);b=S4(b.call($CLJS.R,d,$CLJS.M4,2>c?2:c))}else b=S4(c);return Q4(b,a)};R4.prototype.P=function(a,b){return new R4(this.options,this.Bg,this.Eg,this.de,b)};R4.prototype.O=function(){return this.li};
R4.prototype.Oe=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.qsa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Tj.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.dd(c),a=$CLJS.Gz(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Eg)&&(b=$CLJS.I4.h(this.options),c=$CLJS.B4(b),a=$CLJS.Gz($CLJS.Gz(a,[$CLJS.Xg(b)," "].join(""),c),$CLJS.Xg(b),c));return a};
var W4=new $CLJS.M(null,"number-formatter","number-formatter",929788393),X4=new $CLJS.M(null,"scale","scale",-230427353),Y4=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),zsa=new $CLJS.M(null,"scientific","scientific",316285837);var Z4=new $CLJS.h(null,1,[$CLJS.N4,"symbol"],null),Asa=$CLJS.ok(Z4),xsa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),$4=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.J4)},e,a,b,c,d)}();$4.m(null,$CLJS.Oh,function(a,b){return V4(a,b)});$4.m(null,"percent",function(a,b){return[$CLJS.p.h(V4(100*a,b)),"%"].join("")});$4.m(null,"currency",function(a,b){b=$CLJS.mk.l($CLJS.H([b,Z4]));var c=S4(b);return 1E3>$CLJS.yz(a)?c.Oe(null,a):wsa(c,V4(a,b))});$4.m(null,"scientific",function(a,b){return T4(a,$CLJS.mk.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.K4,1,$CLJS.H4,1],null)])))});
$CLJS.U4=function U4(a,b){b=$CLJS.fA(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.AA);var e=$CLJS.J.g(d,Y4),f=$CLJS.J.g(d,$CLJS.J4),k=$CLJS.J.g(d,X4);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Nj.g(d,X4),U4.g?U4.g(b,f):U4.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,Y4,!1);return U4.g?U4.g(l,m):U4.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?$4.g(a,P4($CLJS.Nj.g(d,$CLJS.AA))):$CLJS.E.g($CLJS.mh.h(f),zsa)?T4(a,d):ysa(a,d)};
module.exports={compact_currency_options_js:Asa,format_number:$CLJS.U4};