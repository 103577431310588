var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var SC,TC,UC,Nga,VC,XC,YC;
$CLJS.RC=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Ne.M(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Hc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};SC=function(a,b){this.db=a;this.Ch=b;this.C=393216;this.I=0};TC=function(a){for(var b=null;;){var c;a=$CLJS.Bn.g(a,b);a=c=$CLJS.an(a)?$CLJS.Vm(a):a;if(!$CLJS.an(c))return a}};UC=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.sd(a)&&!0===$CLJS.Ar.h(a))};
Nga=function(a,b,c){var d=TC($CLJS.Bn.g(a,c));return $CLJS.xp($CLJS.Mm(d),$CLJS.Jm(d),function(){var e=$CLJS.Lm(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.Km($CLJS.Bn.g(d,null));return $CLJS.n(e)?e:c}())};VC=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.z(e);e=$CLJS.B(e);c=$CLJS.Wj.g(function(f){return $CLJS.Bn.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.Wj.g($CLJS.Ur,c),$CLJS.bb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.WC=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,C,G,K,S,X){this.form=a;this.options=b;this.re=c;this.forms=d;this.Ne=e;this.U=f;this.Xd=k;this.za=l;this.children=m;this.min=t;this.Eb=u;this.Rb=v;this.parent=x;this.Ve=A;this.type=C;this.Ti=G;this.cache=K;this.max=S;this.bi=X;this.C=393216;this.I=0};XC=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
YC=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Mi),d=$CLJS.J.g(b,$CLJS.ni),e=$CLJS.J.g(b,$CLJS.tj),f=$CLJS.J.g(b,$CLJS.vs),k=$CLJS.J.g(b,$CLJS.Yo),l=$CLJS.J.g(b,$CLJS.vi);return new XC(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.Do],null))};$CLJS.Y=function(a,b){$CLJS.zh.v(ZC,$CLJS.R,a,b);$CLJS.Te($CLJS.$C,$CLJS.N);return null};SC.prototype.P=function(a,b){return new SC(this.db,b)};SC.prototype.O=function(){return this.Ch};SC.prototype.cd=$CLJS.yc;
SC.prototype.Pc=function(a,b){return $CLJS.vm($CLJS.xm($CLJS.q(this.db)),b)};var aD=function aD(a){switch(arguments.length){case 2:return aD.g(arguments[0],arguments[1]);case 3:return aD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};aD.g=function(a,b){return aD.j(a,b,null)};
aD.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.sj,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Bp.h(x)],null),$CLJS.Cp.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.mk.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?TC($CLJS.Bn.g(a,c)):null;b=$CLJS.n(b)?TC($CLJS.Bn.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.nn.h(a):null,k=$CLJS.n(b)?$CLJS.nn.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.rs,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.us,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.lr,null,$CLJS.sj,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.lr,null,$CLJS.sj,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.sj)&&$CLJS.E.g(k,$CLJS.sj)){l=e($CLJS.Jm(a),$CLJS.Jm(b));var u=$CLJS.Se($CLJS.Sg);a=$CLJS.bb(function(v,x){var A=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(A):C.call(null,A)}()))return $CLJS.bb(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.Yd.g;if($CLJS.E.g(K,A)){K=$CLJS.I(G,0,null);var X=$CLJS.I(G,1,null),T=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var da=$CLJS.I(x,1,null),oa=$CLJS.I(x,2,null);G=UC(G);var Oa=UC(x);G=t.g?t.g(G,Oa):t.call(null,G,Oa);X=$CLJS.mk.l($CLJS.H([X,da]));oa=new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.R.j(X,$CLJS.Ar,$CLJS.Sa(G)),aD.j?aD.j(T,oa,c):aD.call(null,T,oa,c)],
null);K=$CLJS.I(oa,0,null);T=$CLJS.I(oa,1,null);oa=$CLJS.I(oa,2,null);K=$CLJS.Sa(oa)?new $CLJS.P(null,2,5,$CLJS.Q,[K,T],null):$CLJS.n($CLJS.n(T)?!1===$CLJS.Ar.h(T)&&$CLJS.E.g(1,$CLJS.D(T)):T)?new $CLJS.P(null,2,5,$CLJS.Q,[K,oa],null):$CLJS.Sa($CLJS.y(T))?new $CLJS.P(null,2,5,$CLJS.Q,[K,oa],null):!1===$CLJS.Ar.h(T)?new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.Nj.g(T,$CLJS.Ar),oa],null):new $CLJS.P(null,3,5,$CLJS.Q,[K,T,oa],null)}else K=G;return S.call($CLJS.Yd,C,K)},$CLJS.xf,v);$CLJS.zh.j(u,$CLJS.Yd,A);return $CLJS.Yd.g(v,
x)},$CLJS.xf,$CLJS.Wf.g($CLJS.Lm(a),$CLJS.Lm(b)));return $CLJS.xp($CLJS.sj,l,a,c)}return function(v,x){var A=$CLJS.y(v);v=$CLJS.z(A);var C=$CLJS.B(A);A=$CLJS.z(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.z(G);var K=$CLJS.B(G);G=$CLJS.z(K);K=$CLJS.B(K);return $CLJS.xp($CLJS.lr,e(v,x),$CLJS.bf.l(new $CLJS.P(null,1,5,$CLJS.Q,[aD.j(A,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};aD.A=3;
var bD=function bD(a){switch(arguments.length){case 2:return bD.g(arguments[0],arguments[1]);case 3:return bD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};bD.g=function(a,b){return bD.j(a,b,null)};
bD.j=function(a,b,c){return aD.j(a,b,$CLJS.Xj.j($CLJS.Xj.j(c,$CLJS.rs,$CLJS.RC($CLJS.Pd,function(d,e,f){var k=$CLJS.E.g($CLJS.Ur.g(d,null),$CLJS.Ur.g(e,null));return $CLJS.n(k)?d:$CLJS.Bn.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,d,e],null),f)})),$CLJS.us,$CLJS.RC($CLJS.Pd,function(d,e){return $CLJS.n(d)?e:d})))};bD.A=3;
var cD=function cD(a){switch(arguments.length){case 2:return cD.g(arguments[0],arguments[1]);case 3:return cD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};cD.g=function(a,b){return cD.j(a,b,null)};cD.j=function(a,b,c){var d=$CLJS.Wg(b);return Nga(a,function(e){return $CLJS.ff(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};cD.A=3;$CLJS.g=$CLJS.WC.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.WC(this.form,this.options,this.re,this.forms,this.Ne,this.U,this.Xd,this.za,this.children,this.min,this.Eb,this.Rb,this.parent,this.Ve,this.type,this.Ti,this.cache,this.max,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Ya=$CLJS.yc;$CLJS.g.ya=function(){return $CLJS.Gm(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.bo(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.va=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.Hm(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.yc;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.je=$CLJS.yc;$CLJS.g.Kd=function(){return this.za};$CLJS.g=XC.prototype;$CLJS.g.P=function(a,b){return new XC(this.Ve,this.re,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.yc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.hn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.yf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.WC(new $CLJS.uh(function(){return $CLJS.Gn(e.type,b,k,d)}),d,e.re,k,e.Ne,b,e.Xd,l,f,e.min,e.Eb,e.Rb,this,e.Ve,e.type,a,$CLJS.Fn(),e.max,new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.bp],null))};
$CLJS.dD=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var ZC;$CLJS.$C=$CLJS.Se($CLJS.N);
ZC=$CLJS.Se($CLJS.mk.l($CLJS.H([$CLJS.yr(),new $CLJS.h(null,3,[$CLJS.ss,YC(new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.ss,$CLJS.vi,VC(aD)],null)),$CLJS.qs,YC(new $CLJS.h(null,2,[$CLJS.Mi,$CLJS.qs,$CLJS.vi,VC(bD)],null)),$CLJS.ws,YC(new $CLJS.h(null,5,[$CLJS.Mi,$CLJS.ws,$CLJS.vs,1,$CLJS.ni,2,$CLJS.tj,2,$CLJS.vi,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xj.j(b,0,function(d){return $CLJS.Bn.g(d,c)}),$CLJS.Xj.j(b,0,function(d){return $CLJS.Ur.g(d,c)}),$CLJS.Ne.g(cD,$CLJS.Yd.g(b,c))],null)}],null))],
null)])));$CLJS.Te($CLJS.Uk,$CLJS.xm(new SC(ZC,$CLJS.N)));