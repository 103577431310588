var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R1=function(a,b){a=a instanceof $CLJS.M?a.S:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "equal-to":return $CLJS.JD("Equal to");case "default":return $CLJS.JD("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "not-equal-to":return $CLJS.JD("Not equal to");case "excludes":return $CLJS.JD("Excludes");case "default":return $CLJS.JD("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.S:null,b){case "after":return $CLJS.JD("After");case "default":return $CLJS.JD("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.S:null,b){case "before":return $CLJS.JD("Before");case "default":return $CLJS.JD("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.S:null,b){case "is-empty":return $CLJS.JD("Is empty");
case "default":return $CLJS.JD("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.S:null,b){case "not-empty":return $CLJS.JD("Not empty");case "default":return $CLJS.JD("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.JD("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},S1=function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return R1(a,b)}},fqa=new $CLJS.M(null,"after","after",594996914),gqa=new $CLJS.M(null,"equal-to","equal-to",608296653),hqa=new $CLJS.M(null,"excludes","excludes",-1791725945),iqa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),jqa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.T1=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.PI,$CLJS.cF,d,$CLJS.QI,e],null)}function b(d){return c.g?c.g(d,$CLJS.Oh):c.call(null,d,$CLJS.Oh)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.U1=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.Aq),$CLJS.T1.h($CLJS.Eq),$CLJS.T1.h($CLJS.LE),$CLJS.T1.h($CLJS.Cq),$CLJS.T1.h($CLJS.Gq),$CLJS.T1.g($CLJS.KE,$CLJS.ME),$CLJS.T1.g($CLJS.WE,$CLJS.TE)],null);$CLJS.kqa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.ME),$CLJS.T1.h($CLJS.TE),$CLJS.T1.h($CLJS.iF),$CLJS.T1.h($CLJS.bF),$CLJS.T1.h($CLJS.aF),$CLJS.T1.h($CLJS.uF)],null);
$CLJS.lqa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.T1.g($CLJS.zF,hqa),$CLJS.T1.h($CLJS.Hq),$CLJS.T1.g($CLJS.Eq,jqa),$CLJS.T1.g($CLJS.Aq,fqa),$CLJS.T1.h($CLJS.LE),$CLJS.T1.g($CLJS.KE,$CLJS.ME),$CLJS.T1.g($CLJS.WE,$CLJS.TE)],null);$CLJS.mqa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.RE),$CLJS.T1.h($CLJS.Aq),$CLJS.T1.h($CLJS.Eq),$CLJS.T1.h($CLJS.LE),$CLJS.T1.h($CLJS.Cq),$CLJS.T1.h($CLJS.Gq)],null);
$CLJS.nqa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.T1.g($CLJS.Hq,gqa),$CLJS.T1.g($CLJS.zF,iqa),$CLJS.T1.h($CLJS.Aq),$CLJS.T1.h($CLJS.Eq),$CLJS.T1.h($CLJS.LE),$CLJS.T1.h($CLJS.Cq),$CLJS.T1.h($CLJS.Gq),$CLJS.T1.g($CLJS.KE,$CLJS.ME),$CLJS.T1.g($CLJS.WE,$CLJS.TE)],null);
$CLJS.oqa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.iF),$CLJS.T1.h($CLJS.bF),$CLJS.T1.h($CLJS.KE),$CLJS.T1.h($CLJS.WE),$CLJS.T1.h($CLJS.ME),$CLJS.T1.h($CLJS.TE),$CLJS.T1.h($CLJS.aF),$CLJS.T1.h($CLJS.uF)],null);$CLJS.pqa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.KE),$CLJS.T1.h($CLJS.WE),$CLJS.T1.h($CLJS.ME),$CLJS.T1.h($CLJS.TE)],null);
$CLJS.qqa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.g($CLJS.KE,$CLJS.ME),$CLJS.T1.g($CLJS.WE,$CLJS.TE)],null);$CLJS.rqa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.T1.h($CLJS.Hq),$CLJS.T1.h($CLJS.zF),$CLJS.T1.h($CLJS.KE),$CLJS.T1.h($CLJS.WE)],null);$CLJS.V1=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.T1.h($CLJS.Hq),$CLJS.Oh,!0),$CLJS.T1.h($CLJS.Aq),$CLJS.T1.h($CLJS.Eq),$CLJS.T1.h($CLJS.Cq),$CLJS.T1.h($CLJS.Gq),$CLJS.T1.h($CLJS.zF)],null);
$CLJS.I_.m(null,$CLJS.PI,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.cF);b=$CLJS.J.g(b,$CLJS.QI);d=d instanceof $CLJS.M?d.S:null;switch(d){case "default":return S1(a,b);case "long":return R1(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.Q_.m(null,$CLJS.PI,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.cF);b=$CLJS.J.g(c,$CLJS.QI);c=$CLJS.J.g(c,$CLJS.Oh);a=new $CLJS.h(null,3,[$CLJS.G_,$CLJS.$z(a),$CLJS.lD,S1(a,b),$CLJS.j_,R1(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});