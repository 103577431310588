var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var z9,y9,gya,hya,iya,G9,jya,kya,lya,I9,mya,nya,A9,L9,oya,N9,pya,qya,rya;$CLJS.x9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Aa(a,b):null};z9=function(a,b){return y9(a,b,$CLJS.N)};
y9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,A9,!0);if($CLJS.sd(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.x9(b,"_plainObject");d=null==d?null:$CLJS.My(d);return null==d?null:$CLJS.Be(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.Uj.j($CLJS.cf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.x9(b,d)],null)}),$CLJS.Vj.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.za(b))};
gya=function(a){var b=B9.h(a),c=C9.h(a),d=D9.h(a);return $CLJS.Uj.j($CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.mh.h(E9(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.od(b)?$CLJS.Pd:$CLJS.Vj.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Cd(b,f)}),$CLJS.Sa(c)?$CLJS.Pd:$CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.P(null,2,5,
$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};hya=function(a){return z9($CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.mh.h(E9(c));c=$CLJS.E.g(c,$CLJS.BU)?$CLJS.CD:c;b=$CLJS.E.g(c,$CLJS.CD)?$CLJS.mh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
iya=function(a){var b=$CLJS.rG(function(d){d=$CLJS.x9(d,"type");var e=new $CLJS.Rg(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.mh.h($CLJS.x9(b,"type"));return $CLJS.mk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Ci,$CLJS.x9(b,"id"),$CLJS.U,$CLJS.x9(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.S:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.iK,$CLJS.UJ,$CLJS.x9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.Vs,$CLJS.cK],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};G9=function(a){return $CLJS.Wj.g(F9.g($CLJS.jF,$CLJS.N),a)};
jya=function(a,b){var c=F9.g($CLJS.vM,new $CLJS.h(null,1,[A9,!1],null)),d=F9.g($CLJS.vM,$CLJS.N);return $CLJS.mk.l($CLJS.H([$CLJS.R.j(function(){var e=$CLJS.x9($CLJS.x9(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.Ci,b),function(){var e=$CLJS.x9($CLJS.x9(a,"questions"),$CLJS.p.h(b));var f=$CLJS.x9(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};kya=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.H9=new $CLJS.M(null,"cards","cards",169174038);lya=new $CLJS.M(null,"binning-info","binning-info",-2117036653);I9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);mya=new $CLJS.M(null,"db","db",993250759);$CLJS.J9=new $CLJS.M(null,"metrics","metrics",394093469);nya=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.K9=new $CLJS.M(null,"segments","segments",1937535949);A9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
L9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);oya=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.M9=new $CLJS.M(null,"tables","tables",1334623052);N9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);pya=new $CLJS.M(null,"schema-name","schema-name",1666725119);qya=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);rya=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.O9=new $CLJS.M(null,"databases","databases",2040134125);var E9=$CLJS.Ah($CLJS.eA),B9,sya=$CLJS.Se($CLJS.N),tya=$CLJS.Se($CLJS.N),uya=$CLJS.Se($CLJS.N),vya=$CLJS.Se($CLJS.N),wya=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null)),R9,Mya,Nya,Oya,Pya,Qya;B9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.mh,wya,sya,tya,uya,vya);B9.m(null,$CLJS.Oh,function(){return null});
var C9,xya=$CLJS.Se($CLJS.N),yya=$CLJS.Se($CLJS.N),zya=$CLJS.Se($CLJS.N),Aya=$CLJS.Se($CLJS.N),Bya=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));C9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.mh,Bya,xya,yya,zya,Aya);C9.m(null,$CLJS.Oh,function(){return null});var P9,Cya=$CLJS.Se($CLJS.N),Dya=$CLJS.Se($CLJS.N),Eya=$CLJS.Se($CLJS.N),Fya=$CLJS.Se($CLJS.N),Gya=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
P9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","lib-type"),$CLJS.mh,Gya,Cya,Dya,Eya,Fya);var D9,Hya=$CLJS.Se($CLJS.N),Iya=$CLJS.Se($CLJS.N),Jya=$CLJS.Se($CLJS.N),Kya=$CLJS.Se($CLJS.N),Lya=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));D9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Pd,Lya,Hya,Iya,Jya,Kya);D9.m(null,$CLJS.Oh,function(){return null});
var F9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
F9.m(null,$CLJS.Oh,function(a,b){var c=gya(a),d=P9.h(a);return function(e){try{var f=$CLJS.R.j(y9(c,e,b),$CLJS.Vs,d),k=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",k))&&$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.tV("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Ci.h(f),$CLJS.uV(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.tV("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.xh.l($CLJS.H([e])),$CLJS.sU(f)])),f):$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.tV(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.xh.l($CLJS.H([e])),$CLJS.sU(f)])),null)),null;throw l;}}});$CLJS.Q9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.mh.h(f)},e,a,b,c,d)}();
Mya=$CLJS.Se($CLJS.N);Nya=$CLJS.Se($CLJS.N);Oya=$CLJS.Se($CLJS.N);Pya=$CLJS.Se($CLJS.N);Qya=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));R9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.mh,Qya,Mya,Nya,Oya,Pya);
$CLJS.Q9.m(null,$CLJS.Oh,function(a,b){var c=F9.g(a,$CLJS.N);return z9($CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.x9(b,R9.h(a)))});P9.m(null,$CLJS.UO,function(){return $CLJS.eK});B9.m(null,$CLJS.UO,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.RD,null,$CLJS.M9,null],null),null)});
C9.m(null,$CLJS.UO,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "dbms-version":return $CLJS.Ly(b,$CLJS.H([$CLJS.gi,!0]));case "features":return $CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.mh),b);case "native-permissions":return $CLJS.mh.h(b);default:return b}}});R9.m(null,$CLJS.UO,function(){return"databases"});P9.m(null,$CLJS.zj,function(){return $CLJS.SJ});
B9.m(null,$CLJS.zj,function(){return new $CLJS.Rg(null,new $CLJS.h(null,5,[N9,null,$CLJS.RD,null,$CLJS.K9,null,$CLJS.UO,null,$CLJS.J9,null],null),null)});C9.m(null,$CLJS.zj,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "entity-type":return $CLJS.mh.h(b);case "field-order":return $CLJS.mh.h(b);case "initial-sync-status":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);default:return b}}});
$CLJS.Q9.m(null,$CLJS.zj,function(a,b){var c=F9.g(a,$CLJS.N);return z9($CLJS.Uj.g($CLJS.Vj.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.x9(b,"tables"))});P9.m(null,$CLJS.jF,function(){return $CLJS.KJ});
B9.m(null,$CLJS.jF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,6,[N9,null,$CLJS.zj,null,rya,null,$CLJS.UO,null,qya,null,$CLJS.J9,null],null),null)});D9.m(null,$CLJS.jF,function(){return new $CLJS.h(null,6,[$CLJS.Yy,$CLJS.MJ,$CLJS.tk,$CLJS.IV,$CLJS.ZQ,$CLJS.pD,lya,$CLJS.NV,$CLJS.HK,L9,$CLJS.Sr,I9],null)});
C9.m(null,$CLJS.jF,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "base-type":return $CLJS.mh.h(b);case "coercion-strategy":return $CLJS.mh.h(b);case "effective-type":return $CLJS.mh.h(b);case "fingerprint":return $CLJS.sd(b)?$CLJS.uU(b):$CLJS.Ly(b,$CLJS.H([$CLJS.gi,!0]));case "has-field-values":return $CLJS.mh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.jK;case "breakout":return $CLJS.aK;default:return $CLJS.mh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.mh.h(b);
case "semantic-type":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);case "id":return $CLJS.ud(b)?$CLJS.dd(b):b;case "metabase.lib.field/binning":return hya(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.x9(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.Sr,$CLJS.My($CLJS.x9(b,"values")),$CLJS.RJ,$CLJS.My($CLJS.x9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return iya(b);default:return b}}});
F9.m(null,$CLJS.jF,function(a,b){var c=function(){var d=$CLJS.kH(F9,$CLJS.Oh);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.J.g(e,L9);var f=$CLJS.O(d),k=$CLJS.J.g(f,$CLJS.Vs),l=$CLJS.J.g(e,I9);return $CLJS.Nj.l(function(){switch(k instanceof $CLJS.M?k.S:null){case "metadata.column.remapping/external":return $CLJS.R.j(e,$CLJS.XJ,f);case "metadata.column.remapping/internal":return $CLJS.R.j(e,$CLJS.dK,$CLJS.mk.l($CLJS.H([f,l])));default:return e}}(),
L9,$CLJS.H([I9]))}});
$CLJS.Q9.m(null,$CLJS.jF,function(a,b){var c=F9.g(a,$CLJS.N),d=$CLJS.x9(b,"fields");return z9($CLJS.ul(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.sV(f);if($CLJS.n(l))return l;l=$CLJS.dh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.x9(d,l);return m?$CLJS.sV(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.uh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
P9.m(null,$CLJS.vM,function(){return $CLJS.hK});B9.m(null,$CLJS.vM,function(){return new $CLJS.Rg(null,new $CLJS.h(null,11,[N9,null,$CLJS.Pk,null,nya,null,$CLJS.zj,null,mya,null,kya,null,$CLJS.K9,null,$CLJS.UO,null,oya,null,$CLJS.J9,null,pya,null],null),null)});
C9.m(null,$CLJS.vM,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "result-metadata":return $CLJS.n($CLJS.zz($CLJS.rd,$CLJS.Qa)(b))?G9(b):$CLJS.Ly(b,$CLJS.H([$CLJS.gi,!0]));case "fields":return G9(b);case "visibility-type":return $CLJS.mh.h(b);case "dataset-query":return $CLJS.Ly(b,$CLJS.H([$CLJS.gi,!0]));case "dataset":return b;default:return b}}});
$CLJS.Q9.m(null,$CLJS.vM,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.uh(function(){return jya(b,c)})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Sg,$CLJS.ul($CLJS.FV),$CLJS.za($CLJS.x9(b,"tables"))),$CLJS.cf.h($CLJS.sV),$CLJS.za($CLJS.x9(b,"questions"))))});P9.m(null,$CLJS.eF,function(){return $CLJS.QJ});B9.m(null,$CLJS.eF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.zj,null,$CLJS.UO,null],null),null)});
C9.m(null,$CLJS.eF,function(){return function(a,b){return b}});R9.m(null,$CLJS.eF,function(){return"metrics"});P9.m(null,$CLJS.HE,function(){return $CLJS.OJ});B9.m(null,$CLJS.HE,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.zj,null,$CLJS.UO,null],null),null)});C9.m(null,$CLJS.HE,function(){return function(a,b){return b}});R9.m(null,$CLJS.HE,function(){return"segments"});$CLJS.S9=F9.g($CLJS.jF,$CLJS.N);