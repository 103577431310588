var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Xqa,Yqa,Zqa,$qa,f3,g3,h3,i3,ara,bra,cra,dra,era,fra,gra,hra,ira,s3,t3,jra,kra,lra,r3,q3,mra,u3;$CLJS.$2=function(a,b){$CLJS.bb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Xqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.dl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Yqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Cd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Zqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.vW,c,d,e,b)};$CLJS.a3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Xma,""))};$CLJS.b3=function(a,b){return $CLJS.p0.g(a,b instanceof $CLJS.M?b:$CLJS.tk.h(b))};
$CLJS.c3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Wj.g($CLJS.pV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.Q1.v(a,b,f,c)}),d);return $CLJS.Wj.g(function(f){return $CLJS.R.j(f,$CLJS.MY,$CLJS.Cd(e,f))},c)}return null};$CLJS.d3=function(a){return $CLJS.E.g($CLJS.YA(a),$CLJS.lK)};$qa=function(a,b){return $CLJS.n($CLJS.RD.h(a))?$CLJS.Xj.j(a,$CLJS.RD,function(c){return $CLJS.rd(c)?$CLJS.Wj.g(function(d){return $CLJS.e3.g?$CLJS.e3.g(d,b):$CLJS.e3.call(null,d,b)},c):c}):a};
f3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.ud(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.ud(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.jF))try{var e=$CLJS.F(a,3);if($CLJS.ud(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.jF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Ta,k)))}else a=null;return a};g3=function(a){if($CLJS.n(f3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};h3=function(a){return $CLJS.n(f3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
i3=function(a,b){var c=$CLJS.e3;if($CLJS.Sa(f3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
ara=function(a,b,c){return $CLJS.od($CLJS.tK.h(a))?a:$CLJS.n(b)?Zqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tK],null),function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.jF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Xa($CLJS.ub,t)):$CLJS.Xa($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.tO,$CLJS.CU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.e3.g?$CLJS.e3.g(k,c):$CLJS.e3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.XV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.Xj.j(a,$CLJS.tK,function(d){return $CLJS.Wj.g(function(e){return i3(e,$CLJS.H([c]))},d)})};
$CLJS.e3=function(a,b){var c=$CLJS.YA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.GD(a,$CLJS.ED,$CLJS.H([$CLJS.tO,b]));case "metadata/column":return $CLJS.ED(a,$CLJS.cZ,b);case "mbql/join":return c=$CLJS.e1(a),ara($qa($CLJS.ED(a,$CLJS.VD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.j3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.uY,$CLJS.U)(c);a=$CLJS.f1($CLJS.VD.h(a),$CLJS.zz($CLJS.uY,$CLJS.U)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.uY,e,$CLJS.H([$CLJS.KY,b]))};bra=function(a,b){return $CLJS.Sa(b)?a:$CLJS.Wj.g(function(c){var d=h3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.e1(d))?null:i3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.k3=function(a,b){b=bra($CLJS.Wj.g($CLJS.qV,b),$CLJS.e1(a));return $CLJS.ED(a,$CLJS.tK,$CLJS.Be(b))};
$CLJS.l3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.Uj.g($CLJS.cf.h($CLJS.pV),function(){var c=$CLJS.e1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.e3(d,c)}):$CLJS.Pd}()),b));return $CLJS.ED(a,$CLJS.RD,b)};
cra=function(a,b){b=$CLJS.Al(function(d){return $CLJS.Q1.g(d,a)},b);var c=$CLJS.rG($CLJS.zz($CLJS.HZ,$CLJS.IZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.rG($CLJS.HZ,a);if($CLJS.n(b))return b;b=$CLJS.rG($CLJS.IZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};dra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
era=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.k2(b)});var d=new $CLJS.uh(function(){return $CLJS.k2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
fra=function(a,b,c){var d=$CLJS.h_.g($CLJS.E.g($CLJS.Vs.h(b),$CLJS.gV)?b:a,b),e=$CLJS.n(c)?dra($CLJS.h_.g(a,c)):null,f=era(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
gra=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.D1(l,$CLJS.jF)&&$CLJS.zd($CLJS.Q1.v(a,b,l,e)))return $CLJS.e3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.XV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
hra=function(a,b,c,d,e,f){c=gra(a,b,c,d,f);return function t(l,m){try{if($CLJS.ud(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.D1(u,$CLJS.jF))try{var v=$CLJS.F(m,3);if($CLJS.D1(v,$CLJS.jF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.e1(A),S=$CLJS.e1(x);if($CLJS.Sa($CLJS.n(K)?K:S))return $CLJS.n($CLJS.Q1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.e3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.e3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.GD(A,$CLJS.Nj,$CLJS.H([$CLJS.tO])),T=$CLJS.GD(x,$CLJS.Nj,$CLJS.H([$CLJS.tO])),da=null==$CLJS.Q1.v(a,b,X,e);var oa=da?$CLJS.Q1.v(a,b,T,e):da;return $CLJS.n(oa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,T],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(rb){if(rb instanceof Error){var Oa=rb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw rb;}else throw $CLJS.Z;}catch(rb){if(rb instanceof Error){Oa=rb;if(Oa===$CLJS.Z)throw $CLJS.Z;throw Oa;}throw rb;}else throw $CLJS.Z;
}catch(rb){if(rb instanceof Error){Oa=rb;if(Oa===$CLJS.Z)return $CLJS.XV(t,l,m);throw Oa;}throw rb;}}($CLJS.xf,c)};ira=function(a,b){var c=$CLJS.HV();$CLJS.$2(c,b);return c(a)};
$CLJS.m3=function(a,b,c){if($CLJS.Cd(c,$CLJS.VD))return c;var d=$CLJS.EV(a,b),e=$CLJS.U_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Ta,function v(t,u){try{if($CLJS.ud(u)&&1<=$CLJS.D(u))try{var x=$CLJS.bk.j(u,0,1);if($CLJS.ud(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.jF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.zU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.tK.h(c))));f=cra(e,f);var k=ira(fra(a,c,f),$CLJS.Al($CLJS.VD,$CLJS.DM.h(d))),l=$CLJS.S_.h($CLJS.Z0.g(a,$CLJS.qK.h(c)));return $CLJS.e3($CLJS.Xj.j(c,$CLJS.tK,function(t){return $CLJS.Wj.g(function(u){return hra(a,b,u,k,e,l)},t)}),k)};$CLJS.n3=function(a){return $CLJS.tK.h(a)};
$CLJS.o3=function(a){return $CLJS.mk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.uK,$CLJS.CD,a],null),$CLJS.E.g(a,$CLJS.$D)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.p3=function(a,b){b=$CLJS.z($CLJS.qK.h(b));return $CLJS.n($CLJS.NU.h(b))?$CLJS.c_(a,$CLJS.NU.h(b)):$CLJS.n($CLJS.eO.h(b))?$CLJS.a_(a,$CLJS.eO.h(b)):null};
s3=function(a){a=$CLJS.qG(function(d){return $CLJS.IZ(d)?q3:$CLJS.HZ(d)?r3:$CLJS.Rs},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,q3);var c=$CLJS.J.g(b,r3);b=$CLJS.J.g(b,$CLJS.Rs);return $CLJS.bf.l(a,c,$CLJS.H([b]))};t3=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.Wj.g(function(e){return $CLJS.n($CLJS.MY.h(e))?$CLJS.b3(e,$CLJS.i2(c)):e},$CLJS.c3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
jra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.U_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.x_,!1,$CLJS.F_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ul(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.LY);return $CLJS.n(function(){var m=$CLJS.HZ(k);return m?l:m}())?(f=$CLJS.rG(function(m){return $CLJS.E.g(l,$CLJS.Ci.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,u3,f):null):null}),$CLJS.U_.j(a,b,c)))};kra={};
lra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);r3=new $CLJS.M(null,"fk","fk",398442651);q3=new $CLJS.M(null,"pk","pk",-771936732);mra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);u3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.I_.m(null,$CLJS.lK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.qK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.eO);b=$CLJS.J.g(b,$CLJS.NU);c=$CLJS.n(c)?$CLJS.lD.h($CLJS.a_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.c_(a,b),a=$CLJS.n(c)?$CLJS.h_.j(a,0,c):$CLJS.j1(b)):a=null;return $CLJS.n(a)?a:$CLJS.JD("Native Query")});$CLJS.Q_.m(null,$CLJS.lK,function(a,b,c){a=$CLJS.h_.j(a,b,c);c=$CLJS.VD.h(c);return new $CLJS.h(null,2,[$CLJS.U,$CLJS.n(c)?c:a,$CLJS.lD,a],null)});
$CLJS.P_.m(null,$CLJS.lK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.I_.m(null,$CLJS.uK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.CD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.JD("Left outer join");case "right-join":return $CLJS.JD("Right outer join");case "inner-join":return $CLJS.JD("Inner join");case "full-join":return $CLJS.JD("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.Q_.m(null,$CLJS.uK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.CD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.G_,$CLJS.$z(e),$CLJS.lD,$CLJS.h_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.R_.m(null,$CLJS.lK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.VD),k=$CLJS.J.j(e,$CLJS.RD,$CLJS.cz),l=$CLJS.J.g(e,$CLJS.qK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.D_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.fL&&"undefined"!==typeof kra&&"undefined"!==typeof $CLJS.nra&&"undefined"!==typeof $CLJS.v3?new $CLJS.Cc(function(){return $CLJS.v3},$CLJS.kd(mra,new $CLJS.h(null,1,[$CLJS.oj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.qK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.S_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.vd(C)){var G=$CLJS.ic(C),K=$CLJS.D(G),S=$CLJS.le(K);a:for(var X=0;;)if(X<K){var T=$CLJS.hd(G,X);T=$CLJS.GD(T,$CLJS.Nj,$CLJS.H([$CLJS.tO]));T=$CLJS.f_.j(u,-1,T);S.add(T);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.GD(S,$CLJS.Nj,$CLJS.H([$CLJS.tO]));
return $CLJS.ae($CLJS.f_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.Wj.g(function(v){v=$CLJS.R.j(v,$CLJS.ZY,f);v=$CLJS.e3($CLJS.R.l(v,$CLJS.lD,$CLJS.h_.j(a,b,v),$CLJS.H([$CLJS.MJ,$CLJS.HY])),f);return $CLJS.j3(e,m,v)},c)});$CLJS.T_.m(null,$CLJS.lK,function(a,b,c,d){return $CLJS.S_.v(a,b,$CLJS.R.j(c,$CLJS.RD,$CLJS.wx),d)});
var w3,ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.Se($CLJS.N),rra=$CLJS.Se($CLJS.N),sra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ki,$CLJS.hB],null),$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));w3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.YA,sra,ora,pra,qra,rra);w3.m(null,$CLJS.lK,function(a){return a});w3.m(null,$CLJS.gV,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.lK,$CLJS.qK,$CLJS.qK.h($CLJS.CV(a))],null))});
w3.m(null,$CLJS.UU,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.lK,$CLJS.qK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});w3.m(null,$CLJS.hK,function(a){return $CLJS.oV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.lK,$CLJS.qK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.NU,$CLJS.Ci.h(a),$CLJS.Vs,$CLJS.UU],null)],null)],null))});
w3.m(null,$CLJS.SJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.cZ);a=$CLJS.J.g(b,lra);b=w3.h(new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.UU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.yD,$CLJS.p.h($CLJS.ID())],null),$CLJS.eO,$CLJS.Ci.h(b)],null));c=$CLJS.n(c)?$CLJS.e3(b,c):b;return $CLJS.n(a)?$CLJS.l3.g?$CLJS.l3.g(c,a):$CLJS.l3.call(null,c,a):c});
$CLJS.x3=function(){function a(d,e){return $CLJS.k3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.B1(w3.h(d),$CLJS.RD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d,e,f){var k=$CLJS.x3.h(f);f=$CLJS.od($CLJS.n3.h?$CLJS.n3.h(k):$CLJS.n3.call(null,k))?function(){var m=$CLJS.p3.g?$CLJS.p3.g(d,k):$CLJS.p3.call(null,d,k);return $CLJS.y3.j?$CLJS.y3.j(d,e,m):$CLJS.y3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.k3(k,f):k;var l=$CLJS.m3(d,e,f);return $CLJS.PV.l(d,e,$CLJS.Xj,$CLJS.H([$CLJS.DM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.z3=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.EV(d,e),$CLJS.DM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ura=function(){function a(d){d=$CLJS.V0(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.Uj.g($CLJS.ef($CLJS.Qe($CLJS.Cd,d)),$CLJS.cf.h($CLJS.o3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$D,$CLJS.QD,$CLJS.dE,$CLJS.gE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vra=function(){function a(d,e,f,k){var l=$CLJS.d3(f)?$CLJS.e1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.Uj.g($CLJS.cf.h($CLJS.e1),Xqa(function(t){return $CLJS.Tj.g(t,l)})),$CLJS.z3.g(d,e));f=$CLJS.n(k)?k:$CLJS.d3(f)?g3($CLJS.z($CLJS.n3(f))):null;return s3(t3(d,e,f,$CLJS.Vj.g(function(t){t=$CLJS.e1(t);return $CLJS.n(t)?$CLJS.Cd(m,t):null},$CLJS.U_.v(d,e,$CLJS.EV(d,e),new $CLJS.h(null,1,[$CLJS.x_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.wra=function(){function a(d,e,f,k,l){k=$CLJS.d3(f)?$CLJS.p3(d,f):f;var m=$CLJS.d3(f)?$CLJS.e1(f):null;f=$CLJS.n(l)?l:$CLJS.d3(f)?h3($CLJS.z($CLJS.n3(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.GD(f,$CLJS.Nj,$CLJS.H([$CLJS.tO])):f:null;return s3(t3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.MJ,$CLJS.HY);return $CLJS.n(m)?$CLJS.e3(t,m):t},$CLJS.U_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.x_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.xra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.V1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.V1};return b}();
$CLJS.y3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Nj.g(u,$CLJS.l1);v=$CLJS.Nj.g(v,$CLJS.l1);return $CLJS.Y2.l($CLJS.T1.h($CLJS.Hq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Yqa(function(x){return $CLJS.Ci.h(u3.h(x))},jra(d,e,u,v)))}var m=$CLJS.EV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Wj.g(function(v){return k(v,u3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Wj.g(function(u){return k(u3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.yra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.MJ,$CLJS.HY)});
$CLJS.zra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.d3(k)?g3($CLJS.z($CLJS.n3(k))):null;$CLJS.n(l)?(l=$CLJS.k_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.lD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.DV(e,f)){l=$CLJS.z3.g(e,f);var m=$CLJS.od(l);k=m?m:$CLJS.d3(k)?$CLJS.E.g($CLJS.VD.h(k),$CLJS.VD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.WZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.WZ(e),k=$CLJS.a_(e,k),e=$CLJS.h_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.JD("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Ara=function(){function a(d,e,f,k){f=$CLJS.qV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.tk.h(k);e=$CLJS.DV(d,e);l=$CLJS.t0.j(d,e,l);d=$CLJS.t0.j(d,e,m);m=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.tk,l)),k);d=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.tk,d)),k);f=m?$CLJS.Xj.v(f,2,$CLJS.b3,k):f;return d?$CLJS.Xj.v(f,3,$CLJS.b3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.M_.m(null,$CLJS.DM,function(a,b){var c=$CLJS.Be($CLJS.z3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.h_.j(a,b,d)},c);return null==c?null:$CLJS.hs(" + ",c)});